import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class RudderStack {
	public sessionId = '';
	public email: any = '';
	currentProfileId = 0;
	cohortId = 0;

	constructor(private activatedRoute: ActivatedRoute) {
		this.init();
	}

	public init() {
		this.sessionId = this.getUUID();
		this.email = firebase.auth().currentUser?.email;

		this.activatedRoute.params
			.pipe(map((p: any) => Number(p.cohortId)))
			.subscribe(cohort => (this.cohortId = cohort));
	}

	public log(messageJSON: any, cb = () => {}) {
		messageJSON['app'] = 'Learn-V2';
		messageJSON['email'] = this.email;
		messageJSON['sessionId'] = this.sessionId;
		messageJSON['clientTimestamp'] = new Date().getTime();
		messageJSON['page'] = location.pathname;
		if (this.currentProfileId) {
			messageJSON['profileId'] = this.currentProfileId;
		}

		if (this.cohortId) {
			messageJSON['cohortId'] = this.cohortId;
		}

		(<any>window)['rudderanalytics'].track(
			messageJSON.type,
			messageJSON,
			() => {
				// block

				cb();
			},
		);
	}

	public getUUID() {
		const length = 16;
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}
