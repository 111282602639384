<ion-app>
	<ng-container *ngIf="authService.error.length > 0">
		<div class="toast-box">
			<div
				class="error-message p-16 Body_M"
				*ngFor="let error of authService.error"
			>
				{{ error }}
			</div>
		</div>
	</ng-container>

	<ion-router-outlet></ion-router-outlet>
</ion-app>
