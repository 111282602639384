import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { InterfaceAPIStatus } from '../../model/loader';
import * as NgrxLearningActions from './actions/ngrx.actions';
import { loadInit } from './actions/ngrx.actions';
import {
	selectApiFilters,
	selectApiStatus,
	selectData,
} from './selector/ngrx.selectors';

@Injectable({
	providedIn: 'root',
})
export class NgrxService {
	constructor(private store: Store) {}

	init(service: any, ...args: any[]) {
		this.store.dispatch(
			loadInit({
				service,
				args,
			}),
		);
	}

	loadInitAPI(service: any, ...args: any[]): Observable<boolean> {
		return this.store.select(selectApiStatus(service, ...args)).pipe(
			filter(apiStatus => apiStatus === InterfaceAPIStatus.none),
			first(),
			tap(() =>
				this.store.dispatch(
					NgrxLearningActions.loadAPIStatus({
						status: InterfaceAPIStatus.start,
						service,
						args,
					}),
				),
			),
		);
	}

	loadMoreInitAPI(service: any, ...args: any[]): Observable<boolean> {
		return this.store.select(selectApiStatus(service, ...args)).pipe(
			first(),
			tap(() =>
				this.store.dispatch(
					NgrxLearningActions.loadAPIStatus({
						status: InterfaceAPIStatus.loadMoreStart,
						service,
						args,
					}),
				),
			),
		);
	}

	loadInitData(service: any, ...args: any[]) {
		let data = args.splice(-1)[0];
		this.store.dispatch(
			NgrxLearningActions.loadInitData({
				service,
				args,
				data,
			}),
		);
	}

	loadMoreDataPagination(service: any, ...args: any[]) {
		let data = args.splice(-1)[0];

		this.store.dispatch(
			NgrxLearningActions.loadMoreDataPagination({
				service,
				args,
				data,
			}),
		);
	}

	loadInitDataPagination(service: any, ...args: any[]) {
		let pageRecord = args.splice(-1)[0];
		let data = args.splice(-1)[0];

		this.store.dispatch(
			NgrxLearningActions.loadInitDataPagination({
				service,
				args,
				data,
				pageRecord,
			}),
		);
	}

	loadData(service: any, ...args: any[]): any {
		return this.store
			.select(selectData(service, ...args))
			.pipe(map(data => JSON.parse(data)));
	}

	loadFilter(service: any, ...args: any[]): any {
		return this.store
			.select(selectApiFilters(service, ...args))
			.pipe(map(data => JSON.parse(data)));
	}

	setFilter(service: any, ...args: any[]) {
		let filter = args.splice(-1)[0];

		this.resetState(service, ...args);
		// this.store.dispatch(
		// 	NgrxLearningActions.loadAPIStatus({
		// 		status: InterfaceAPIStatus.none,
		// 		service,
		// 		args,
		// 	}),
		// );

		this.store.dispatch(
			NgrxLearningActions.setFilters({
				service,
				args,
				filter,
			}),
		);
	}

	resetFilter(service: any, ...args: any[]) {
		this.store.dispatch(
			NgrxLearningActions.resetFilters({
				service,
				args,
			}),
		);
	}

	resetState(service: any, ...args: any[]) {
		this.store.dispatch(
			NgrxLearningActions.loadAPIStatus({
				status: InterfaceAPIStatus.none,
				service,
				args,
			}),
		);
	}

	deleteFromState(service: any, ...args: any[]) {
		// let keyValue = args.pop();
		let id = args.pop();
		this.store.dispatch(
			NgrxLearningActions.deleteInitData({
				service,
				args,
				data: [],
				id,
			}),
		);
	}

	editInState(service: any, ...args: any[]) {
		let key = args.pop();
		let value = args.pop();
		let id = args.pop();
		let dataId = args.pop();

		if (service !== 'learning') {
			let temp = [args.pop()];
			args = temp;
		}
		this.store.dispatch(
			NgrxLearningActions.updateInitData({
				service,
				args,
				id,
				key,
				value,
				dataId,
			}),
		);
	}
}
