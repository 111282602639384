/* eslint-disable @ngrx/prefer-action-creator */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface State {}

export class ActionTypes {
	static LOGOUT = '[App] logout';
}

export class Logout implements Action {
	readonly type = ActionTypes.LOGOUT;
}

export function clearState(reducer: any) {
	return (state: any, action: any) => {
		if (action.type === ActionTypes.LOGOUT) {
			state = {};
		}

		return reducer(state, action);
	};
}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = [clearState];
