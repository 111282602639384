/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

// import { CookieService } from 'ngx-cookie-service';
import { error } from './errors';
import { SignUpService } from './sign-up/sign-up.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	error: string[] = [];

	constructor(
		private authFire: AngularFireAuth,
		private signUpService: SignUpService,
		private router: Router,
		public ngZone: NgZone, // private cookieService: CookieService,
	) {}

	earthTokenExists() {
		// if (this.cookieService.get('earth_token')) {
		// 	const token = this.cookieService.get('earth_token');
		// 	this.loginWithCustomToken(token);
		// }
	}

	loginWithCustomToken(token: any, bool = false) {
		this.authFire
			.setPersistence('local')
			.then(() => this.authFire.signInWithCustomToken(token))
			.then(res => {
				if (bool) {
					this.fetchAndSetToken(res.user);
				}
				this.ngZone.run(() => {
					this.router.navigate(['app/jobfair']);
				});
			})
			.catch(e => {
				console.log(e);
			});
	}

	removeToastMsg(str: any) {
		this.error = this.error.filter(err => err !== str);
	}

	addToastMsg(str: any, timeout = 3) {
		this.error.push(str);

		setTimeout(() => {
			this.removeToastMsg(str);
		}, timeout * 1000);
	}

	public signIn(email: string, password: string) {
		return this.authFire
			.signInWithEmailAndPassword(email, password)
			.then(u2 => {
				this.fetchAndSetToken(u2.user);

				this.addToastMsg('Redirecting to home page!', 1);
				this.ngZone.run(() => {
					this.router.navigate(['app/jobfair']);
				});
			})
			.catch(e => this.showError(e));
	}

	public googleSignIn() {
		this.authFire
			.signInWithPopup(new firebase.auth.GoogleAuthProvider())
			.then(u2 => {
				this.fetchAndSetToken(u2.user);
				this.ngZone.run(() => {
					this.router.navigate(['app/jobfair']);
				});
			})
			.catch(e => this.showError(e));
	}

	public signUp(
		name: any,
		email: any,
		password: any,
		subscribedToNewsletter: any,
	) {
		return this.signUpService
			.init(name, email, password, subscribedToNewsletter)
			.then(() => {
				this.ngZone.run(() => {
					this.router.navigate(['app/jobfair']);
				});
			})
			.catch(e => this.showError(e));
	}

	public googleSignUp(subscribedToNewsletter: any) {
		return this.signUpService
			.googleInit(subscribedToNewsletter)
			.then(() => {
				this.ngZone.run(() => {
					this.router.navigate(['app/jobfair']);
				});
			})
			.catch(e => this.showError(e));
	}

	public signOut() {
		return this.authFire
			.signOut()
			.then(() => {
				this.addToastMsg('Redirecting to login page!', 1);

				this.ngZone.run(() => {
					// this.cookieService.delete('earth_token', '/', '.terra.do');

					this.router.navigate(['/auth/login'], { replaceUrl: true });
				});
			})
			.catch(e => this.showError(e));
	}

	public redirectAuth(
		url: 'forgot-password' | 'forgot-email' | 'sign-up' | 'login',
	) {
		this.router.navigateByUrl(`/auth/${url}`, { skipLocationChange: true });
	}

	public resetPassword(email: any) {
		return this.authFire
			.sendPasswordResetEmail(email)
			.then(() => {
				this.addToastMsg(
					'We have sent a link to ' + email + ' to reset your password.',
				);
				this.ngZone.run(() => {
					this.router.navigate(['/auth/login']);
				});
			})
			.catch(e => this.showError(e));
	}

	private showError(e: any) {
		const index = error.findIndex(x => x.code === e.code);

		if (index !== -1) {
			const err = error[index];
			this.addToastMsg(err.message);
		} else {
			this.addToastMsg('Oops, something went wrong!');
		}

		throw new Error('error');
	}

	public fetchAndSetToken(user3: any) {
		fetch(
			`https://us-central1-hubble-prod-dcbd4.cloudfunctions.net/minttoken?uid=${user3.uid}`,
			{
				method: 'GET',
			},
		)
			.then(response => response.json())
			.then(data => {
				this.setCookie('earth_token', data.token);
			});
	}

	public setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;

		// Set it expire in 7 days
		date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

		// Set it
		// this.cookieService.set(name, value, date, '/', '.terra.do');
		/*this.window.document.cookie =
			name +
			'=' +
			value +
			';expires=' +
			date.toUTCString() +
			';path=/;domain=.terra.do;';*/
	}
}
