export enum InterfaceAPIStatus {
	none = 'none', // It Means API Forcefully fire
	start = 'start', // It Means API is start
	end = 'end', // It Means API is end
	loadMoreStart = 'loadMoreStart', // It Means API Read More is start
	loadMoreEnd = 'loadMoreEnd', // It Means API Read More is end
	noData = 'noData', // It Means API is end
}

export interface InterfaceAPI {
	data: any;
	apiStatus: InterfaceAPIStatus;
	isNext?: boolean;
	pageRecord?: number;
	hasNextPage?: boolean;
}

export const interfaceApiDefaultValue: InterfaceAPI = {
	data: [],
	apiStatus: InterfaceAPIStatus.none,
};

export const apiRecordData = (state: any) => {
	try {
		const isSkeleton =
			state.apiStatus === InterfaceAPIStatus.none ||
			state.apiStatus === InterfaceAPIStatus.start;
		const isNoDataFound =
			state.apiStatus === InterfaceAPIStatus.end && state.data.length === 0;

		let isLoadMore = false;

		if (
			state.apiStatus === InterfaceAPIStatus.end ||
			state.apiStatus === InterfaceAPIStatus.loadMoreEnd
		) {
			if (state.isNext === true || state.isNext === false) {
				isLoadMore = state.isNext;
			} else if (
				state.data.length !== 0 &&
				!!state.pageRecord &&
				state.pageRecord !== 0
			) {
				isLoadMore = state.data.length < state.pageRecord;
			}
		}
		const isLoadMoreStart =
			state.apiStatus === InterfaceAPIStatus.loadMoreStart;
		const isLoadMoreEnd = state.apiStatus === InterfaceAPIStatus.loadMoreEnd;

		let data = false;

		if (!isNoDataFound && !isSkeleton) {
			data = state.data;
		}

		if (Object.keys(state).includes('hasNextPage')) {
			return {
				isSkeleton,
				isNoDataFound,
				isLoadMore,
				isLoadMoreStart,
				isLoadMoreEnd,
				data,
				hasNextPage: state?.hasNextPage,
				filter: {},
				pageRecord: state?.pageRecord,
			};
		}
		return {
			isSkeleton,
			isNoDataFound,
			isLoadMore,
			isLoadMoreStart,
			isLoadMoreEnd,
			data,
			filter: state.filter,
			pageRecord: state?.pageRecord,
		};
	} catch (e) {
		state = interfaceApiDefaultValue;
		const isSkeleton =
			state.apiStatus === InterfaceAPIStatus.none ||
			state.apiStatus === InterfaceAPIStatus.start;
		const isNoDataFound =
			state.apiStatus === InterfaceAPIStatus.end && state.data.length === 0;

		let isLoadMore = false;

		if (
			state.apiStatus === InterfaceAPIStatus.end ||
			state.apiStatus === InterfaceAPIStatus.loadMoreEnd
		) {
			if (state.isNext === true || state.isNext === false) {
				isLoadMore = state.isNext;
			} else if (
				state.data.length !== 0 &&
				!!state.pageRecord &&
				state.pageRecord !== 0
			) {
				isLoadMore = state.data.length < state.pageRecord;
			}
		}
		const isLoadMoreStart =
			state.apiStatus === InterfaceAPIStatus.loadMoreStart;
		const isLoadMoreEnd = state.apiStatus === InterfaceAPIStatus.loadMoreEnd;

		let data = false;

		if (!isNoDataFound && !isSkeleton) {
			data = state.data;
		}

		return {
			isSkeleton,
			isNoDataFound,
			isLoadMore,
			isLoadMoreStart,
			isLoadMoreEnd,
			pageRecord: state?.pageRecord,
			data,
			filter: {},
		};
	}
};
