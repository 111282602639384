import { createFeatureSelector, createSelector } from '@ngrx/store';
import { apiRecordData, InterfaceAPIStatus } from '../../../model/loader';
import { ngrxFeatureKey } from '../reducer/ngrx.reducer';

export const selectLearningState = createFeatureSelector<any>(ngrxFeatureKey);

export const selectApiStatus = (...args: any[]) =>
	createSelector(selectLearningState, state =>
		state[`${args.join('_')}`]?.apiStatus
			? state[`${args.join('_')}`].apiStatus
			: InterfaceAPIStatus.none,
	);

export const selectData = (...args: any[]) =>
	createSelector(selectLearningState, state =>
		JSON.stringify(apiRecordData(state[`${args.join('_')}`])),
	);

export const selectApiFilters = (...args: any[]) =>
	createSelector(selectLearningState, state =>
		JSON.stringify(state[`${args.join('_')}`].filter),
	);
